<template>
  <div>
    开发中
  </div>
</template>

<script>

export default {

  data() {
    return {


    };
  },
  mounted() {

  },
  methods: {
  }
}
</script>
